<template>
  <div
    class="min-h-screen min-w-screen flex flex-col justify-between"
  >
    <div>
      <AppHeader />

      <main
        container="padded base"
      >
        <slot />
      </main>
    </div>

    <LazyAppFooter />
  </div>
</template>
